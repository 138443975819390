<template id="perfiles">
<div class="page-perfiles">
  <v-container>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </v-container>

  <v-container fluid fill-height>
    <v-row :justify="'center'" :align="'center'">
      <v-col sm='12'>
        <base-material-card color="primary" icon="library_books" title="Administrar Perfiles" class="elevation-1 px-5 py-3">

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab dark x-small color="secondary" slot="activator" @click.native="agregar" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon>
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid">

              <v-client-table ref="vuetable" :data="items" :columns="columns" :options="options" class="elevation-2 pa-2">
                <template slot="noResults">
                  <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                </template>
                <template slot="actions" slot-scope="props">

                  <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                    <template v-slot:activator>
                      <v-btn x-small fab dark color="primary">
                        <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                        <v-icon v-else>reorder</v-icon>
                      </v-btn>
                    </template>
                    <v-btn x-small fab dark color="blue" @click.native="get_perfil(props.row)" title="Editar Registro" v-tippy>
                      <v-icon>edit</v-icon>
                    </v-btn>
                    <v-btn x-small fab dark color="pink"
                      v-if="props.row.nombre!='ADMINISTRADOR'"
                      @click.native="eliminar(props.row)" title="Eliminar Registro" v-tippy>
                      <v-icon>delete</v-icon>
                    </v-btn>
                  </v-speed-dial>

                </template>

              </v-client-table>
            </div>
          </v-card-text>

        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Perfil</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? actualizar() : guardar()">
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <span v-if="model.nombre=='ADMINISTRADOR'" style="font-size:18px;"><strong>ADMINISTRADOR</strong><br<br></span>
                <v-text-field label="Nombre" v-model="model.nombre"
                v-else
                :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-text-field label="Descripción" v-model="model.descripcion" :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="12" lg="12">
                <v-switch v-model="model.estatus" :label="model.estatus" true-value="Activo" false-value="Inactivo"></v-switch>
              </v-col>
            </v-row>
            <v-card-text>
              <v-row>
                <v-col xs="6">
                  <v-text-field text prepend-icon="search" placeholder="Buscar" v-model="searchpermisos" hide-details class="hidden-sm-and-down"></v-text-field>
                </v-col>
              </v-row>
              <br>
              <v-data-table hide-default-footer :items-per-page="100" :headers="permisos.headers" :search="searchpermisos" :items="permisos.items" class="elevation-1" v-model="permisos.selected" item-key="clave" show-select>
                <template slot="no-data">
                  <v-alert :value="true" color="error" icon="warning">
                    No hay datos...
                  </v-alert>
                </template>

                <template slot="items" slot-scope="permisos">
                  <tr>
                    <td>
                      <v-checkbox v-model="permisos.selected" primary hide-details></v-checkbox>
                    </td>
                    <td>{{ permisos.item.categoria }}</td>
                    <td>{{ permisos.item.nombre }}</td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? actualizar() : guardar()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>


<script>
export default {
  mounted: function() {
   /*  if (!this.verificaPermiso('t.utilerias.perfiles')) {
      this.$router.replace("AccessDenied").catch(() => {});
    } */
  },
  created: function() {
    this.model = this.clean_model();
    this.get_perfiles();
  },
  data() {
    return {
      id_configuracion: '',
      fab: [],
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Utilerías",
          disabled: true,
          href: ""
        },
        {
          text: "Perfiles",
          disabled: true,
          href: ""
        }
      ],
      columns: [
        "nombre",
        "descripcion",
        "estatus",
        "actions"
      ],
      options: {
        filterable: ["nombre", "descripcion", "estatus"],
        sortable: ["nombre", "descripcion", "estatus"],

        headings: {
          nombre: "Nombre",
          descripcion: "Descripción",
          estatus: "Estatus",
          actions: "Operaciones"
        },
        listColumns: {
            estatus: [{
                    id: 'Activo',
                    text: "Activo"
                },
                {
                    id: 'Inactivo',
                    text: "Inactivo"
                }
            ]
        },
      },
      searchpermisos: "",
      search: "",
      permisos: {
        selected: [],
        headers: [{
            text: "Categoría",
            value: "categoria",
            width: "30%"
          },
          {
            text: "Permiso",
            value: "nombre",
            width: "60%"
          }
        ],
        items: [0]
      },
      modal: false,
      update: false,
      model: {},
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      items: [],
    }
  },
  methods: {
    clean_model: function() {
      return {
        uuid: "",
        nombre: "",
        descripcion: "",
        permisos: [],
        estatus: "Activo",
      };
    },
    get_perfiles: function() {
      window.dialogLoader.show('Espere un momento por favor..');

      let data = {
        "selector": {
          "type": "configuracion"
        },
        "fields": ["_id", "perfiles", "permisos"]
      };

      window.axios
        .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/', data)
        .then(response => {
          if (response.data.docs.length > 0) {
            this.id_configuracion = response.data.docs[0]._id;
            this.items = response.data.docs[0].perfiles != undefined ? response.data.docs[0].perfiles : [];
            this.permisos.items = response.data.docs[0].permisos;
          }

          window.dialogLoader.hide();
        })
        .catch(error => {
          console.log(error)
          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener los registros.",
            footer: ""
          });
          window.dialogLoader.hide();
        });
    },
    get_perfil: function(item) {
      this.model = {
        uuid: item.uuid,
        nombre: item.nombre,
        descripcion: item.descripcion,
        permisos: item.permisos,
        estatus: item.estatus,
      };
      this.editar();
    },
    agregar: function() {
      this.model = this.clean_model();
      this.update = false;
      this.modal = true;
      this.permisos.selected = [];
    },
    editar: function() {
      this.update = true;
      this.modal = true;
      this.permisos.selected = this.model.permisos;
    },
    guardar: function() {
      if (this.$refs.form.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/_design/funciones_configuracion/_update/agregar_perfiles/" + this.id_configuracion;
        this.model.permisos = this.permisos.selected;

        let data = {
          uuid: this.$uuid.v4(),
          nombre: this.model.nombre.toString().trim(),
          descripcion: this.model.descripcion.toString().trim(),
          permisos: this.model.permisos,
          estatus: this.model.estatus,
        };

        window.axios
          .post(url, data)
          .then(response => {
            if (response.data == true) {
              this.modal = false;
              this.get_perfiles();
              window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
                color: 'success'
              });
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: response.data,
                footer: ""
              });
            }
          })
          .catch(error => {
            this.$swal({
              type: "error",
              title: "¡Operación no Permitida!",
              text: "Ocurrió un error al guardar el registro.",
              footer: ""
            });
          }).then(() => {
            window.dialogLoader.hide();
          });

      }
    },
    actualizar: function() {
      if (this.$refs.form.validate()) {
        window.dialogLoader.show('Espere un momento por favor..');
        this.model.permisos = this.permisos.selected;

        let data = this.model;
        let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/_design/funciones_configuracion/_update/update_perfil/" + this.id_configuracion;

        window.axios
          .put(url, data)
          .then(response => {
            if (response.data == true) {
              this.modal = false;
              this.get_perfiles();
              window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
                color: 'success'
              });
            } else {
              this.$swal({
                type: "error",
                title: "¡Operación no Permitida!",
                text: response.data,
                footer: ""
              });
            }
          })
          .catch(error => {
            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
              color: 'error'
            });
          }).then(() => {
            this.modal = false;
            window.dialogLoader.hide();
          });
      }
    },
    eliminar: function(item) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          let url = process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE+ "/_design/funciones_configuracion/_update/delete_perfil/" + this.id_configuracion;

          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .post(url, item)
            .then(response => {
              if (response.data == true) {
                this.modal = false;
                this.get_perfiles();
                window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                  color: 'success'
                });
              } else {
                this.$swal({
                  type: "error",
                  title: "¡Operación no Permitida!",
                  text: response.data,
                  footer: ""
                });
              }
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });

            }).then(() => {
              window.dialogLoader.hide();
            });
        }
      });
    },
  }
}
</script>
